import * as ElliLight from '@elli-eco/design-system/Elli/Light';

const tokens = ElliLight;
const { common, button } = tokens;

export const elliColors = {
  colorAccent: common.ColorSurfaceAccentBoldDefault,
  colorAlertPrimary: common.ColorSurfaceAlertBoldDefault,
  colorAlertSecondary: common.ColorSurfaceWarningBoldDefault,
  colorAlertSecondaryDark: common.ColorSurfaceWarningBoldActive,
  colorConfirmation: common.ColorSurfaceSuccessSoftOnHighlight,
  colorInfoSurfaceSoft: common.ColorSurfaceInfoSoftDefault,
  colorNegativeTextSoft: common.ColorTextAlert,
  colorPositiveTextSoft: common.ColorTextSuccess,
  colorPrimary: common.ColorSurfaceBrandBoldDefault,
  colorPrimaryDark: common.ColorSurfaceBrandBoldActive,
  colorWarningTextSoft: common.ColorTextWarning,
  surfaceLightPurplePink: common.ColorSurfaceBrandBoldHover,
  surfacePrimary: common.ColorBackgroundPrimary,
  surfaceSecondary: common.ColorBackgroundSecondary,
  surfaceTertiary: common.ColorBackgroundTertiary,
  textPrimary: common.ColorTextPrimary,
  textSecondary: common.ColorTextSecondary,
  textSuccess: common.ColorTextSuccess,
  button,
  common,
};
