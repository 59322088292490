import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid, { Grid2Props as GridProps } from '@mui/material/Unstable_Grid2';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ChevronRight } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: '175px',
    maxWidth: '230px',
  },
  subtitle: {
    fontSize: '0.75rem',
    lineHeight: '1.8',
    fontWeight: 'normal',
    color: 'white',
    padding: theme.spacing(0, 0, 1, 0),
  },
  header: {
    color: 'white',
    padding: theme.spacing(1, 2, 1, 2),
    height: '105px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: '1.14rem',
    lineHeight: '1.3',
    fontWeight: 'bold',
  },
  content: {
    backgroundColor: 'white',
    color: 'black',
    padding: theme.spacing(2),
    height: '110px',
    overflow: 'visible',
  },
  description: {
    fontSize: '0.875rem',
  },
  cardActions: {
    fontSize: '1.8rem',
    height: '30px',
  },
}));

export type NavigationItemProps = {
  id: string;
  title: string;
  subtitle?: string;
  description: string;
  color: string;
  path: string;
  disabled?: boolean;
};

type OverviewCardProps = {
  items: NavigationItemProps[];
  justifyContent?: GridProps['justifyContent'];
};

const OverviewCard = ({
  title,
  subtitle,
  disabled,
  description,
  color,
  path,
}: NavigationItemProps) => {
  const classes = useStyles();
  const isExternalLink = path.startsWith('https://');
  const target = isExternalLink ? '_blank' : '_self';

  return (
    <Grid>
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={path}
          target={target}
          disabled={disabled}
        >
          <div className={classes.header} style={{ backgroundColor: color }}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </div>
          <CardContent className={classes.content}>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {!disabled && <ChevronRight />}
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export const OverviewCards = ({ items }: OverviewCardProps) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {items.map(item => (
        <OverviewCard key={item.id} {...item} />
      ))}
    </Grid>
  );
};
