import {
  CatalogKindExploreContent,
  DomainExplorerContent,
  ExploreLayout,
  GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { NavigationItemProps, OverviewCards } from '../shared/OverviewCards';
import { elliLight } from '../../themes/elli-light';
import { elliColors } from '../../themes/elli-colors';

const palette = elliLight.getTheme('v4')?.palette!!;

const members = {
  copArchitecture: 'CoP Architecture',
  copApiDesign: 'CoP API Design',
  copInfosec: 'CoP InfoSec',
  copBusinessIt: 'CoP Business IT',
  teamTortellini: 'Team Tortellini',
  copSoftwareCraftsmanship: 'CoP Software Craftsmanship',
  copCicd: 'CoP CI/CD',
};

const copCards: NavigationItemProps[] = [
  {
    id: 'ecp-architecture',
    title: 'Software Architecture',
    subtitle: members.copArchitecture,
    description:
      'Learn about architecture at Elli and the Architecture Principles..',
    color: palette.primary.light,
    path: '/catalog/default/component/Heart.wiki.architecture/docs',
  },
  {
    id: 'eco-api-design',
    title: 'API Design Guidelines',
    subtitle: members.copApiDesign,
    description: 'Global API design guidelines and best practices.',
    color: palette.primary.light,
    path: 'https://docs.google.com/document/d/1oNBQ8qS637Uvo3ASbtjs0XRU07Dp64rR-oPmXNCGnMA/edit#heading=h.rnkuymvkad37',
  },
  {
    id: 'eco-infosec',
    title: 'InfoSec',
    subtitle: members.copInfosec,
    description:
      'All topics regarding information security, guidelines and incident handling.',
    color: palette.primary.light,
    path: '/catalog/default/component/infra.infosec/docs',
  },
  {
    id: 'eco-pipelines',
    title: 'CI/CD & Pipelines',
    subtitle: members.copCicd,
    description:
      'Information how to set up CI/CD pipelines and best practices.',
    color: palette.primary.light,
    path: '/catalog/default/component/docs.cicd/docs',
  },
  {
    id: 'design-system',
    title: 'Component Library',
    subtitle: members.teamTortellini,
    description:
      'Learn about Elli design system, components and how to use them.',
    color: palette.primary.light,
    path: 'https://component-library.elli.eco/',
  },
  {
    id: 'tool-buildmon',
    title: 'Build Monitor',
    subtitle: members.copSoftwareCraftsmanship,
    description: 'Monitor the status of your builds and deployments.',
    color: palette.primary.light,
    path: 'https://buildmon.sw.elli.eco/',
  },
  // {
  //   id: 'eco-software-development',
  //   title: 'Software Development',
  //   subtitle: members.copSoftwareCraftsmanship,
  //   description:
  //     'Coming soon! Guidelines of good software development at Elli, information about testing and tooling.',
  //   color: elliColors.common.ColorSurfaceDisabledOn,
  //   path: '/docs/default/component/...',
  //   disabled: true,
  // },
];

const guideCards = [
  {
    id: 'guide-getting-started',
    title: 'Getting started at Elli',
    description: 'A guide to support you during your first days at Elli.',
    color: elliColors.common.ColorSurfaceSuccessBoldActive,
    path: '/catalog/default/Component/docs.getting-started/docs',
  },
  {
    id: 'learning-academy',
    title: 'Elli Engineering Academy',
    description:
      'EAA provides focused onboarding content on technologies, tools, frameworks and working methods.',
    color: elliColors.common.ColorSurfaceSuccessBoldActive,
    path: '/catalog/default/component/infra.documentation.engineering-academy/docs',
  },
];

const serviceCards = [
  {
    id: 'new-repository',
    title: 'Create a new repository',
    subtitle: members.copArchitecture,
    description: 'Support to create the correct IaC for your new repository.',
    color: elliColors.colorPrimaryDark,
    path: '/create/templates/default/create-repository-v1',
  },
  {
    id: 'new-service',
    title: 'Setup a new service',
    subtitle: members.copArchitecture,
    description: 'Coming soon...',
    color: elliColors.common.ColorSurfaceDisabledOn,
    path: '/docs/default/component/...',
    disabled: true,
  },
];

const Headline: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Box mb={2} mt={5}>
    <Typography variant="h4">{children}</Typography>
  </Box>
);

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the Elli ecosystem"
      subtitle="Browse through tools, domains and documentation"
    >
      <ExploreLayout.Route path="docs" title="Docs">
        <div>
          <Headline>The Elli Ecosystem</Headline>
          <OverviewCards items={copCards} />
          <Headline>Guides & Education</Headline>
          <OverviewCards items={guideCards} />
          <Headline>Tools for Developers</Headline>
          <OverviewCards items={serviceCards} />
        </div>
      </ExploreLayout.Route>
      <ExploreLayout.Route path="graph" title="Graph">
        <CatalogGraphPage
          initialState={{
            rootEntityRefs: ['domain:Elli-Commercial', 'domain:MSP'],
            selectedKinds: ['domain', 'group'],
          }}
        />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent title="Domains" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent title="Groups" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="System" title="Systems" />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
