/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  FeaturedDocsCard,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { Content, InfoCard, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import LuyIcon from '../../icons/Luy_icon.png';
import ElliBolt from '../../icons/Elli_bolt_purple.png';
import ElliLogo from '../../icons/Elli_logo.png';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  toolIcon: {
    maxWidth: '80%',
  },
  path: {
    fill: '#7df3e1',
  },
}));

export function HomePage() {
  const classes = useStyles();
  const { svg, container, toolIcon } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<img src={ElliLogo} alt="Elli Logo" className={svg} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://luy.eam.elli.eco/',
                      label: 'LUY',
                      icon: <img src={LuyIcon} alt="" className={toolIcon} />,
                    },
                    {
                      url: 'https://docs.google.com/presentation/d/19wi9ufDoK3toB3JL7Z76BG8rqnrUTzITGel3wFq2yi0/edit#slide=id.gd2a534df99_0_274',
                      label: 'Pasta Teams',
                      icon: <img src={ElliBolt} alt="" className={toolIcon} />,
                    },
                    {
                      url: 'https://buildmon.sw.elli.eco/',
                      label: 'Build Monitor',
                      icon: <img src={ElliBolt} alt="" className={toolIcon} />,
                    },
                    {
                      url: 'https://dev.azure.com/elli-eco/Heartbeat/_git/tools.cli',
                      label: 'Elli CLI',
                      icon: <img src={ElliBolt} alt="" className={toolIcon} />,
                    },
                    {
                      url: 'https://lookerstudio.google.com/reporting/b1c1ae14-545a-48af-b368-aa37b14ee562/page/0RynC?pli=1',
                      label: 'Pipeline Analytics',
                      icon: <img src={ElliBolt} alt="" className={toolIcon} />,
                    },
                    {
                      url: 'https://lookerstudio.google.com/reporting/f9521b1e-3d6e-4b58-a3b1-485baea6922f/page/p_gjtsmeuozc',
                      label: 'CoP Workshop Analytics',
                      icon: <img src={ElliBolt} alt="" className={toolIcon} />,
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} lg={4}>
                <FeaturedDocsCard
                  title="Getting started at Elli"
                  filter={{ 'metadata.name': 'docs.getting-started' }}
                  subLinkText="View all onboarding guides"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FeaturedDocsCard
                  title="Architecture Documentation"
                  filter={{ 'metadata.name': 'Heart.wiki.architecture' }}
                  subLinkText="Learn more"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <InfoCard title="Share your feedback!">
                  <Typography>
                    Is something missing in the documentation? Should we add
                    more useful links? Or do you have any other feedback for us?
                  </Typography>
                  <Box mt={2}>
                    <Link
                      href="https://forms.gle/psd5F9pTE6fHNGwY8"
                      target="_blank"
                      style={{ fontSize: '1rem' }}
                    >
                      Let us know in our form!
                    </Link>
                  </Box>
                </InfoCard>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
}
