import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { QetaPage } from '@drodil/backstage-plugin-qeta';

import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  discoveryApiRef,
  IdentityApi,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { setTokenCookie } from './cookieAuth';
import { HomePage } from './components/home/HomePage';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { explorePage } from './components/explore/ExplorePage';
import { UnifiedThemeProvider } from '@backstage/theme';
import { elliLight } from './themes/elli-light';

const app = createApp({
  components: {
    SignInPage:
      process.env.NODE_ENV === 'production'
        ? props => {
            if (window.location.hostname !== 'backstage.sw.elli.eco') {
              window.location.replace(
                `https://backstage.sw.elli.eco${window.location.pathname}`,
              );
              return null;
            }
            const discoveryApi = useApi(discoveryApiRef);
            return (
              <SignInPage
                {...props}
                auto
                provider={{
                  id: 'azure-auth-provider',
                  title: 'Azure',
                  message: 'Sign in using Microsoft AD',
                  apiRef: microsoftAuthApiRef,
                }}
                onSignInSuccess={async (identityApi: IdentityApi) => {
                  await setTokenCookie(
                    await discoveryApi.getBaseUrl('cookie'),
                    identityApi,
                  );

                  props.onSignInSuccess(identityApi);
                }}
              />
            );
          }
        : undefined,
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {});
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'elli-light',
      title: 'Elli Theme (Light)',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={elliLight} children={children} />
      ),
    },
  ],
  featureFlags: [
    {
      pluginId: '', // pluginId is required for feature flags in plugins. It can be left blank for a feature flag leveraged in the application.
      name: 'enable-experimental-templating-component',
      description:
        'Enables the experimental templates component in the create page',
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog-import" element={<CatalogImportPage />} />;
    <Route
      path="/catalog"
      element={<CatalogIndexPage initiallySelectedFilter="owned" />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage initialFilter="owned" />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
      </TechDocsAddons>
    </Route>
    <Route
      path="/api-docs"
      element={<ApiExplorerPage initiallySelectedFilter="owned" />}
    />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{ maxDepth: 3, selectedKinds: ['services'] }}
        />
      }
    />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
