import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';
import { elliColors } from './elli-colors';

const pageBorderLeft: {
  borderLeftStyle: 'solid';
  borderLeftWidth: string;
  borderLeftColor: string;
} = {
  borderLeftStyle: 'solid',
  borderLeftWidth: '1px',
  borderLeftColor: '#FFFFFF',
};

export const elliLight = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: elliColors.colorPrimary,
      },
      secondary: {
        main: elliColors.colorAccent,
      },
      error: {
        main: elliColors.colorAlertPrimary,
      },
      warning: {
        main: elliColors.colorAlertSecondary,
      },
      info: {
        main: elliColors.colorPrimaryDark,
      },
      success: {
        main: elliColors.textSuccess,
      },
      background: {
        default: elliColors.surfaceSecondary,
        paper: elliColors.surfacePrimary,
      },
      banner: {
        info: elliColors.colorInfoSurfaceSoft,
        error: elliColors.colorAlertPrimary,
        text: elliColors.textPrimary,
        link: elliColors.textPrimary,
      },
      divider: elliColors.surfaceSecondary,
      errorBackground: elliColors.colorAlertPrimary,
      warningBackground: elliColors.colorAlertSecondary,
      infoBackground: elliColors.colorInfoSurfaceSoft,
      navigation: {
        background: '#FFFFFF',
        indicator: elliColors.colorAccent,
        color: elliColors.textPrimary,
        selectedColor: elliColors.colorPrimary,
        navItem: { hoverBackground: elliColors.colorInfoSurfaceSoft },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Inter',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.swagger-ui .renderedMarkdown code': {
          color: '#000000 !important',
          backgroundColor: `${elliColors.surfacePrimary} !important`,
          padding: '2px 4px !important',
          borderRadius: '4px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: 'none',
          fontSize: '16px',
        },
        text: ({ theme }) => ({
          color: theme.palette.primary.main,
          textTransform: 'none',
        }),
        containedPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor:
              elliColors.button.ButtonVariantPrimaryHoverBackgroundColor,
            color: elliColors.button.ButtonVariantPrimaryHoverColor,
            boxShadow: 'none',
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
        h4: {
          fontSize: '22px',
        },
        h5: {
          fontSize: '18px',
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        root: {
          padding: '8px 0px 0px 0px',
        },
        title: {
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        underlineHover: {
          textDecoration: 'underline',
          fontWeight: 'normal',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          backgroundImage: 'unset',
          boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 8px 0px',
          marginBottom: '0px',
          ...pageBorderLeft,
        }),
        title: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontSize: '26px',
        }),
        subtitle: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        type: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        breadcrumb: ({ theme }) => ({
          color: theme.palette.text.secondary,
          fontStyle: 'normal',
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        tabsWrapper: {
          zIndex: 100,
          boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 8px 0px',
          ...pageBorderLeft,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        value: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    BackstageBreadcrumbsClickableText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    BackstageBreadcrumbsStyledBox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {
          ...pageBorderLeft,
          borderLeftWidth: 0,
        },
      },
    },
    BackstageIconLinkVertical: {
      styleOverrides: {
        label: {
          textDecoration: 'none',
        },
      },
    },
    BackstageTableHeader: {
      styleOverrides: {
        header: { textTransform: 'none' },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
  },
});
